<template>
  <div class="career">
    <section :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }" class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h1 class="display-1 mb-3 text-white">{{ langcontent.career }}</h1>
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ol class="breadcrumb text-white">
                <li class="breadcrumb-item">
                  <router-link to="/">{{ langcontent.home }}</router-link>
                </li>
                <li aria-current="page" class="breadcrumb-item active">{{ langcontent.career }}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light">
      <div class="container py-14">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <div class="row gy-10 gx-lg-8 gx-xl-12">
              <div class="col-lg-12">
                <h2 class="display-4 mb-3 text-center">{{ langcontent.cform }}</h2>
                <form class="contact-form needs-validation" method="post" novalidate>
                  <div class="row gx-4">
                    <div class="col-md-6">
                      <div class="form-floating mb-4">
                        <input id="form_name"
                               :class="{ error: v$.name.$errors.length }"
                               v-model="name" :placeholder="langcontent.name" @input="setName" class="form-control" required
                               type="text">
                        <label for="form_name">{{ langcontent.name }} *</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating mb-4">
                        <input id="form_lastname"
                               :class="{ error: v$.surname.$errors.length }"
                               v-model="surname" :placeholder="langcontent.surname" @input="setSurname" class="form-control"
                               required type="text">
                        <label for="form_lastname">{{ langcontent.surname }} *</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating mb-4">
                        <input id="form_email"
                               :class="{ error: v$.mail.$errors.length }"
                               v-model="mail" :placeholder="langcontent.mail" @input="setMail"  class="form-control" required
                               type="email">
                        <label for="form_email">{{ langcontent.mail }} *</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating mb-4">
                        <input id="form_phone"
                               :class="{ error: v$.phone.$errors.length }" @input="setPhone"
                               v-model="phone" :placeholder="langcontent.tel" class="form-control" required
                               type="number">
                        <label for="form_email">{{ langcontent.tel }} *</label>
                      </div>
                    </div>





                    <div class="col-md-6">
                      <div class="form-select-wrapper mb-4">
                        <select :class="{ error: v$.gender.$errors.length }" @input="setGender"
                                id="form-select1" class="form-select" v-model="gender" required>
                          <option disabled selected value="">{{ langcontent.gender }}  *</option>
                          <option value="Erkek">{{ langcontent.male }} </option>
                          <option value="Kadın">{{ langcontent.female }} </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-select-wrapper mb-4">
                        <select
                            :class="{ error: v$.military.$errors.length }" @input="setMilitary"
                            id="form-select2" class="form-select" v-model="military" required>
                          <option disabled selected value="">{{ langcontent.military }} *</option>
                          <option value="Yapıldı">{{ langcontent.okey }} </option>
                          <option value="Yapılmadı">{{ langcontent.nope }} </option>
                          <option value="Muaf">{{ langcontent.joker }} </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-select-wrapper mb-4">
                        <select
                            :class="{ error: v$.edu.$errors.length }" @input="setEdu"
                            id="form-select2" class="form-select" v-model="edu" required>
                          <option disabled selected value="">{{ langcontent.edu }}  *</option>
                          <option value="ilk">{{ langcontent.edu1 }}</option>
                          <option value="orta">{{ langcontent.edu2 }}</option>
                          <option value="lise">{{ langcontent.edu3 }} </option>
                          <option value="lisans">{{ langcontent.edu4 }} </option>
                          <option value="yükseklisans">{{ langcontent.edu5 }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-select-wrapper mb-4">
                        <select
                            :class="{ error: v$.work.$errors.length }" @input="setWork"
                            id="form-select2" class="form-select" v-model="work" required>
                          <option disabled selected value="">{{ langcontent.workpart }}  *</option>
                          <option value="insan">{{ langcontent.humanr }}</option>
                          <option value="satis">{{ langcontent.salesm }} </option>
                          <option value="personel">{{ langcontent.piya }} </option>
                          <option value="sofor">{{ langcontent.driver }} </option>
                        </select>
                      </div>
                    </div>



                    <div class="col-12">
                      <div class="form-floating mb-4">
                    <textarea id="form_message"
                              :class="{ error: v$.desc.$errors.length }"
                              v-model="desc" class="form-control" @input="setDesc"  placeholder="Your message" required
                              style="height: 150px"></textarea>
                        <label>{{ langcontent.mydesc }}... *</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <a class="btn btn-primary rounded-pill btn-send mb-3 " @click="post"><span>{{
                          langcontent.send
                        }}</span></a>
                      <p class="text-muted">* {{ langcontent.formerr }} </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="popup" :class="{'acc': popupText}" @click="popup">
      <div class="content">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></i>
        <p>{{ popupText }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import iso from "../axios";
import axios from "axios";
import useVuelidate from '@vuelidate/core'

import {email, minLength, required} from "@vuelidate/validators";
export default {
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      langcontent: [],
      name: "",
      surname: "",
      mail: "",
      phone: "",
      gender: "",
      military: "",
      edu: "",
      work: "",
      desc: "",
      popupText:null
    }
  },
  validations() {
    return {
      name: {required},
      surname: {required},
      mail: {required,email},
      phone: {required,minLength: minLength(10)},
      gender: {required},
      military: {required},
      edu: {required},
      work: {required},
      desc: {required},
    }
  },
  created() {
    iso.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Bimark | '+this.langcontent.career;
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods:{
    setName ($event) {
      this.name = $event.target.value.
      this.v$.name.$touch()
    },
    setSurname ($event) {
      this.surname = $event.target.value
      this.v$.surname.$touch()
    },
    setMail ($event) {
      this.mail = $event.target.value.toLowerCase()
      this.v$.mail.$touch()
    },
    setPhone ($event) {
      this.phone = $event.target.value.toUpperCase()
      this.v$.phone.$touch()
    },
    setGender ($event) {
      this.gender = $event.target.value
      this.v$.gender.$touch()
    },
    setMilitary ($event) {
      this.military = $event.target.value
      this.v$.military.$touch()
    },
    setEdu ($event) {
      this.edu = $event.target.value
      this.v$.edu.$touch()
    },
    setWork ($event) {
      this.work = $event.target.value
      this.v$.work.$touch()
    },
    setDesc ($event) {
      this.desc = $event.target.value
      this.v$.desc.$touch()
    },
    async post () {
      const result = await this.v$.$validate()
      console.log(this.v$.$validate())
      if (!result) {
        return
      }
      const data = new FormData();
      // data.append('dil', localStorage.getItem('lang'))
      data.append('name', this.name)
      data.append('surname', this.surname)
      data.append('mail', this.mail)
      data.append('tel', this.phone)
      data.append('gender', this.gender)
      data.append('military', this.military)
      data.append('edu', this.edu)
      data.append('pos', this.work)
      data.append('desc', this.desc)

      axios.post('https://yonet.gifadwork.com/apipost/bimark/career', data)
          .then(response =>{
            this.popupText = this.langcontent.succesform
            this.name = null
            this.surname = null
            this.mail = null
            this.phone = null
            this.gender = null
            this.military = null
            this.edu = null
            this.work = null
            this.desc = null
            window.scrollTo(0, 0);
          })
          .catch(error => console.log(error))
    },
    popup: function (){
      if(this.popupText != null){
        this.popupText = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style>
input.error{
  color: #ff0000!important;
  background-color: rgba(255, 0, 0, 0.05) !important;
  border: 1px solid rgb(255, 0, 0) !important;
  box-shadow: 0 0 1.25rem rgba(255, 0, 0, 0.03)!important;
}textarea.error{
   color: #ff0000!important;
   background-color: rgba(255, 0, 0, 0.05) !important;
   border: 1px solid rgb(255, 0, 0) !important;
   box-shadow: 0 0 1.25rem rgba(255, 0, 0, 0.03)!important;
 }select.error{
     background-color: rgba(255, 0, 0, 0.05) !important;
     border: 1px solid rgb(255, 0, 0) !important;
     box-shadow: 0 0 1.25rem rgba(255, 0, 0, 0.03)!important;
   }

.popup{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fffd;
  width: 100vw;
  height: 100vh;
}
.popup.acc{
  display: block;
}
.popup .content {
  display: block;
  position: relative;
  width: 300px;
  left: calc(50% - 150px);
  background-color: #fff;
  top: 50%;
  padding: 20px;
  padding-top: 35px;
  box-shadow: rgb(93 23 23 / 25%) 0px 25px 50px -12px;
}
.popup .content i {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>